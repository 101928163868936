import "../Styles/index.scss";

import mixitup from "mixitup";
import { toggle as slidetoggle } from "slidetoggle";
import { defineCustomElement as defineCustomElementCore, createApp, type App, type Component } from "vue";
import { mdiClose } from "@mdi/js";
import { initializeMaps } from "@wagich/bluc-map";
import { Vuetify3Dialog } from "vuetify3-dialog";

import { vuetify } from "./vuetify";
import { ContactForm } from "./contact-form";

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp(app) {
			app.use(vuetify);

			app.config.globalProperties.$icons = {
				mdiClose,
			};
		},
	})
}

customElements.define("contact-form", defineAndConfigureCustomElement(ContactForm));

document.querySelector(".footerContact.open-contactform")?.addEventListener("click", e => {
	let target = document.createElement("div");
	target.className = "contact-form-overlay";
	document.body.appendChild(target);

	let app = createApp(ContactForm, { source: "Bodyfeet Praxis" });
	app.use(vuetify);
	app.use(Vuetify3Dialog);
	app.config.globalProperties.$icons = {
		mdiClose,
	};
	app.mount(target);

	e.preventDefault();
});
document.addEventListener("close-contact-form", () => {
	document.querySelector(".contact-form-overlay")?.remove();
});

// filter
let offersElement = document.querySelector(".offers");
if (offersElement != null) {
	mixitup(offersElement, {
		selectors: {
			target: ".offer",
			control: ".filterBtn"
		}
	});
}

for (let element of document.querySelectorAll(".filter:not(.all)")) {
	let filterClass = element.getAttribute("data-filter");
	if (filterClass == null || filterClass.length === 0) {
		continue;
	}
	if (document.querySelectorAll(filterClass).length === 0) {
		element.classList.add("is-hidden");
	}
}

let locationNavElement = document.querySelector<HTMLElement>(".locationNav");
for (let element of document.querySelectorAll(".filterDescription")) {
	locationNavElement?.dispatchEvent(new Event("click"));
}
locationNavElement?.addEventListener("click", () => {
	slidetoggle(".locationNavSec", {});
	locationNavElement?.classList.toggle("open");
});

for (let element of document.querySelectorAll(".locationNav > li > a")) {
	element.addEventListener("click", e => {
		e.preventDefault();
	});
}

document.querySelector("#navToggle")?.addEventListener("click", () => {
	document.querySelector("nav")?.classList.toggle("is-open");
})

initializeMaps();

